

















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputSwitchCpt, InputTextareaCpt } from '@/core/components';

@Component({
  components: { InputSwitchCpt, InputTextareaCpt },
})
export default class QcInputSwitchWithTextareaCpt extends Vue {
  @Prop() report: object;
  @Prop() qualityControl: object;

  @Prop() switchProp: string;
  @Prop() switchLabel: string;
  @Prop() switchQcdProp: boolean;

  @Prop() txtProp: string;
  @Prop() txtLabel: string;
  @Prop() txtQcdProp: boolean;
}
