




























import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputSelectCpt } from '@/core/components';

@Component({
  components: { InputSelectCpt },
})
export default class QcInputSelectCpt extends Vue {
  @Prop() report: object;
  @Prop() qualityControl: object;
  @Prop() lookups: object[];
  @Prop({ default: false }) multiple: boolean;

  @Prop() prop: string;
  @Prop() label: string;
  @Prop() placeholder: string;
  @Prop() qcdProp: boolean;
}
