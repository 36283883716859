









































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputSelectCpt } from '@/core/components';
import {
  IllnessLookupModel,
  IllnessModel,
  IllnessQualityControlModel,
  InjuryLookupModel,
  InjuryModel,
  InjuryQualityControlModel,
  OsicsLookupModel,
} from '@/core/webapi';
import { OsicsService } from '@/core/services';

@Component({
  components: { InputSelectCpt },
})
export default class QcOsicsCpt extends Vue {
  @Prop() report: InjuryModel | IllnessModel;
  @Prop() qualityControl: InjuryQualityControlModel | IllnessQualityControlModel;
  @Prop() lookups: InjuryLookupModel | IllnessLookupModel;
  @Prop() showRegionOsics: boolean;

  osicsCode(model: InjuryModel | InjuryQualityControlModel | IllnessModel | IllnessQualityControlModel) {
    return OsicsService.getOsicsCode(
      this.lookups.osics!,
      model.osicsRegionId,
      model.osicsParentId,
      model.osicsSpecificId,
      model.osicsDetailId,
    );
  }

  getOsicsRegionPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsRegionPrefix(osics);
  }

  getReportOsicsParentPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsParentPrefix(osics, this.osicsCode(this.report));
  }

  getReportOsicsSpecificPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsSpecificPrefix(osics, this.osicsCode(this.report));
  }

  getReportOsicsDetailPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsDetailPrefix(osics, this.osicsCode(this.report));
  }

  getQcOsicsParentPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsParentPrefix(osics, this.osicsCode(this.qualityControl));
  }

  getQcOsicsSpecificPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsSpecificPrefix(osics, this.osicsCode(this.qualityControl));
  }

  getQcOsicsDetailPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsDetailPrefix(osics, this.osicsCode(this.qualityControl));
  }

  onOsicsRegionChanged() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.qualityControl.osicsParentId;
    this.onOsicsParentChanged();
  }

  onOsicsParentChanged() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.qualityControl.osicsSpecificId;
    this.onOsicsSpecificChanged();
  }

  onOsicsSpecificChanged() {
    delete this.qualityControl.osicsDetailId;
  }

  getOsicsChildren(regionId: number, parentId?: number, specificId?: number) {
    return OsicsService.getOsicsChildren(this.lookups.osics!, regionId, parentId, specificId);
  }
}
