










import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { QcStatus } from '@/core/constants';

@Component
export default class ReturnStatusTagCpt extends AppVue {
  @Prop() statusId: number;
  @Prop() text: string;

  get type() {
    if (this.statusId === QcStatus.Complete) {
      return 'success';
    }

    if (this.statusId === QcStatus.AwaitingReporter || this.statusId === QcStatus.New) {
      if (this.isReporter) {
        return 'warning';
      }
    }

    if (this.statusId === QcStatus.AwaitingAnalyst) {
      if (this.isAnalyst) {
        return 'warning';
      }
    }

    return 'info';
  }

  get effect() {
    return this.type === 'success' ? 'dark' : 'plain';
  }

  get iconClass() {
    if (this.type === 'success') {
      return 'fas el-icon-fa-check';
    }

    if (this.type === 'warning') {
      return 'fas el-icon-fa-clock';
    }

    return null;
  }
}
