
























import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputRadioCpt } from '@/core/components';

@Component({
  components: { InputRadioCpt },
})
export default class QcInputRadioCpt extends Vue {
  @Prop() report: object;
  @Prop() qualityControl: object;
  @Prop() lookups: object[];

  @Prop() prop: string;
  @Prop() label: string;
  @Prop() qcdProp: boolean;
}
