
























import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputTextareaCpt } from '@/core/components';

@Component({
  components: { InputTextareaCpt },
})
export default class QcInputTextareaCpt extends Vue {
  @Prop() report: object;
  @Prop() qualityControl: object;

  @Prop() prop: string;
  @Prop() label: string;
  @Prop() placeholder: string;
  @Prop() qcdProp: boolean;
}
