
























import { Component, Vue, Prop } from 'vue-property-decorator';

import { SimpleLookupModel } from '@/core/webapi';
import { LookupValueCpt } from '@/core/components';

@Component({
  components: {
    LookupValueCpt,
  },
})
export default class DetailsMultiSelectLookupRowCpt extends Vue {
  @Prop() label: string;
  @Prop() lookups: SimpleLookupModel[];
  @Prop() lookupIds: number[];
}
