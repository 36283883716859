
























import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputDateCpt } from '@/core/components';

@Component({
  components: { InputDateCpt },
})
export default class QcInputDateCpt extends Vue {
  @Prop() report: object;
  @Prop() qualityControl: object;
  @Prop() pickerOptions?: object;

  @Prop() prop: string;
  @Prop() label: string;
  @Prop() qcdProp: boolean;
}
