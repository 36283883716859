






















import { Component, Vue, Prop } from 'vue-property-decorator';

import { IllnessModel, InjuryModel, OsicsLookupModel } from '@/core/webapi';
import { OsicsService } from '@/core/services';

@Component
export default class OsicsDetailsCpt extends Vue {
  @Prop() model: InjuryModel | IllnessModel;
  @Prop() osics: OsicsLookupModel[];
  @Prop() reportType: string; // Injury / Illness

  get osicsCode() {
    return OsicsService.getOsicsCode(
      this.osics,
      this.model.osicsRegionId,
      this.model.osicsParentId,
      this.model.osicsSpecificId,
      this.model.osicsDetailId,
    );
  }

  get osicsRegion() {
    return OsicsService.getOsicsText(this.osics, this.model.osicsRegionId);
  }

  get osicsType() {
    return OsicsService.getOsicsText(this.osics, this.model.osicsRegionId, this.model.osicsParentId);
  }

  get osicsSpecifics() {
    return OsicsService.getOsicsText(
      this.osics,
      this.model.osicsRegionId,
      this.model.osicsParentId,
      this.model.osicsSpecificId,
    );
  }

  get osicsDetail() {
    return OsicsService.getOsicsText(
      this.osics,
      this.model.osicsRegionId,
      this.model.osicsParentId,
      this.model.osicsSpecificId,
      this.model.osicsDetailId,
    );
  }
}
