





import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { StatusTagCpt } from '@/core/components';
import { ReturnToPlayStatus } from '@/core/constants';

@Component({ components: { StatusTagCpt } })
export default class ReturnStatusTagCpt extends AppVue {
  @Prop() statusId: number;
  @Prop() text: string;

  get type() {
    if (this.statusId === ReturnToPlayStatus.Returned) {
      return 'success';
    }

    if (this.statusId === ReturnToPlayStatus.ReturnDayPassed) {
      return 'warning';
    }

    return 'info';
  }
}
