

































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputSwitchCpt, InputDateCpt } from '@/core/components';

@Component({
  components: { InputSwitchCpt, InputDateCpt },
})
export default class QcInputSwitchDateCpt extends Vue {
  @Prop() report: object;
  @Prop() qualityControl: object;
  @Prop() pickerOptions?: object;

  @Prop() switchProp: string;
  @Prop() switchLabel: string;
  @Prop() switchQcdProp: boolean;

  @Prop() date1Prop: string;
  @Prop() date1Label: string;
  @Prop() date1QcdProp: boolean;

  @Prop() date2Prop?: string;
  @Prop() date2Label?: string;
  @Prop() date2QcdProp?: boolean;
}
