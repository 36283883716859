


















import { Component, Vue, Prop } from 'vue-property-decorator';

import { InputDateCpt } from '@/core/components';

@Component({
  components: { InputDateCpt },
})
export default class QcFormElementWithAlertCpt extends Vue {
  @Prop() model: object;
  @Prop() qcdProp: boolean;
  @Prop({ default: true }) hasLabel: boolean;

  qcAwaitingReporterId = 2;
}
